<template>

  <section class="carousel-section ">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
            <img style="width: 100%;object-fit: cover;height: 15vh;" loading="lazy" class="d-block w-100" src="img/carousel/karibu.png"> 
        </div>
        <div class="carousel-item ">
            <img style="width: 100%;object-fit: cover;height: 15vh;" loading="lazy" class="d-block w-100" src="img/carousel/jackpot.jpeg">
        </div>
        <div class="carousel-item ">
            <img style="width: 100%;object-fit: cover;height: 15vh;" loading="lazy" class="d-block w-100" src="img/carousel/deposit.jpeg">
        </div>
        <div class="carousel-item ">
            <img style="width: 100%;object-fit: cover;height: 15vh;" loading="lazy" class="d-block w-100" src="img/carousel/freespin.png"  > 
        </div>
        <div class="carousel-item ">
            <img style="width: 100%;object-fit: cover;height: 15vh;" loading="lazy" class="d-block w-100" src="img/carousel/signup.jpeg">
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
    </div>
  </section>

</template>
<script>
export default {
  name: "Slider",
}
</script>